.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cnxt-bottom-border {
  border-bottom: 1px solid;
}

.cnxt-font {
  font-family: 'Overpass', sans-serif;
  font-size: 12px;
}

.cnxt-font-lg {
  font-family: 'Overpass', sans-serif;
  font-size: 14px;
}

.od-font-sm {
  font-family: 'Overpass', sans-serif;
  font-size: 12px;
  vertical-align: top;
}

.cnxt-standard {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px !important;
}

.cnxt-small {
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px !important;
}

.cnxt-header {
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px !important;
  font-weight: bold;
}

.cnxt-tabcolorselected {
  background-color: aliceblue;
}

/* body {
  /* font-family: 'Titillium Web', sans-serif;
} */ 

body {
  font-family: 'Overpass', sans-serif !important;
  overflow-y: scroll;
  background-color: rgb(248,249,250);
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.InovuaReactDataGrid__header,
.InovuaReactDataGrid__row {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
}

.MuiTreeItem-label {
  font-family: 'Overpass', sans-serif !important;
  font-size: 13px !important;
  color: rgb(0, 0, 139);
}

.form-label,
.nav-link,
.form-control,
.btn,
.card-header,
.react-datetime-picker__wrapper {
  font-family: 'Titillium Web', sans-serif !important;
  font-size: 14px !important;
}

.custom-clustericon {
  background: var(--cluster-color);
  color: #fff;
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.custom-clustericon::before {
  padding: 7px;
}

.custom-clustericon::after {
  padding: 14px;
}

.custom-clustericon-1 {
  --cluster-color: #00a2d3;
}

.custom-clustericon-2 {
  --cluster-color: #ff9b00;
}

.custom-clustericon-3 {
  --cluster-color: #ff6969;
}

.cnxt-btn-pull-right {
  text-align: right;
}

.apexcharts-text,
.apexcharts-legend-text {
  font-family: 'Titillium Web', sans-serif !important;
}

.cnxt-height {
  min-height: 390px;
}

.cnxt-hsep {
  background-color: black;
  height: 1px;
}

.green {
  color: #198754;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.accordion {
  min-width: 234px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.od-font {
  font-family: 'Overpass', sans-serif;
}

.od-standard {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px !important;
}

.od-small {
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px !important;
}

.od-header {
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px !important;
  font-weight: bold;
}

body {
  font-family: 'Overpass', sans-serif;
  overflow-y: scroll;
  background-color: rgb(248, 249, 250);
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.offcanvas-body {
  height: 1000px;
  overflow-y: hidden !important;
}

.accordion-header {
  font-size: 12px !important;
}

.sunburst-viz {
  font-family: 'Overpass', sans-serif !important;
}

.sunburst-viz text {
  font-family: 'Overpass', sans-serif !important;
  fill: white;
}

.radial-label {
  display: none !important;
}

.angular-label {
  display: block !important;
}

.show {
  visibility: visible;
}

/* .noscroll {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
} 

html, body {
  overflow: auto;
} */

.sticky-offset-main{
  /* padding-top:56px !important;
  padding-bottom:10px !important; */
}

.od-main{
  top:55px !important;
}

.sidebar-expanded {
  width: 230px;
}


.sticky-offset {
  top: 56px !important;
}

.body-row {
  margin-left: 0;
  margin-right: 0;
}

.btn-white{
  color: #fff !important;
}

.od-table{
  table-layout: fixed;
}
.d2c
{
  background-color: aliceblue !important;
}
.c2d{
  background-color: beige !important;
}
.InovuaReactDataGrid__column-header{
  font-family: 'Titillium Web', sans-serif;
  font-size: 10px !important;
  padding: 0!important;
  height: 30px !important;

}
.text-bold{
  font-weight: bold;
}

.od-btn-smaller{
  font-size: smaller !important;
  font-weight: bold !important;
}

.card-header-info{
  background-color: #0dcaf0!important;
}

.opdash-visible .nav-link{
  color:white !important
}

.opdash-hidden{
  display: none;
}
.gtstabs .nav-link{
  /* color: black !important; */
}